.info-banner {
  width: 100%;
  /* height: 20vh; */
  /* overflow-x: scroll; */
}
/*.info-banner::-webkit-scrollbar {
  display: none;
} */
.info-banner__item {
  height: 300px;
  /* margin: 10px 30px; */
}

.info-banner__img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.info-banner .slick-slider .slick-dots {
  bottom: -15px;
}
.info-banner .slick-slider .slick-arrow {
  /* left: 10px; */
  /* background-color: red; */
}
.info-banner .slick-slider .slick-prev {
  left: 20px;
  z-index: 1;
  /* righ */
}
.info-banner .slick-slider .slick-next {
  right: 10px;
}
@media screen and (max-width: 1000px) {
  .info-banner__item {
    height: 250px;
  }
}

@media screen and (max-width: 650px) {
  .info-banner__item {
    height: 150px;
  }
  .info-banner .slick-slider .slick-arrow,
  .info-banner .slick-slider .slick-dots {
    display: none !important;
  }
}

.bcard {
  height: 300px;
  /* width: 250px; */
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 0 25px 1px var(--bs-color);
  background: var(--card-color);
}
/* .bcard-img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 28%,
    rgba(0, 0, 0, 0.2) 81%
  );
}

body.dark .bcard-img::after {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 28%,
    rgba(255, 255, 255, 0.2) 81%
  );
} */

.bcard-img {
  height: 60%;
  width: 100%;
}

.bcard-img img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  /* transform: scale(1.2); */
}
body.dark .bcard-title {
  background: #833ab4; /* fallback for old browsers */
  background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  background-clip: text;
  color: transparent; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bcard-title {
  background: #ffa17f; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #00223e,
    #ffa17f
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #00223e,
    #ffa17f
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-clip: text;
  color: transparent;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-weight: 800;
  font-size: 16px;
}

.bcard-content {
  color: var(--text-color);
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-weight: 300;
  font-size: 12px;
  word-wrap: break-word;
}
body.light .bcard-content {
  color: #000 !important;
}