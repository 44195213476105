.sidebar {
  position: relative;
  height: 100%;
  width: 100%;
  transition: 0.3s all ease-in-out;
  /* margin: 10px 0px 0px 10px; */
  border-radius: 20px;
  border: 1px solid var(--text-color);
  /* border-right: 1px solid var(--text-color); */
  /* border-bottom: 1px solid var(--text-color); */
  /* border-bottom-right-radius: 20px;
  border-top-right-radius: 20px; */
  background-color: var(--bg-color);
  box-shadow: 0 1px 3px var(--text-color), 0 1px 2px var(--text-color);
}
.sidebar-link {
  cursor: pointer;
}

.sidebar-link {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.sidebar-link:hover .sidebar-link__icon {
  font-size: 40px;
  color: var(--text-color);
}
.sidebar-link:hover .sidebar-link__text {
  color: var(--text-color);
}
.sidebar-link.active {
  color: var(--text-color);
}

.sidebar-link__icon {
  font-size: 30px;
  font-weight: bold;
  color: var(--text-color);
  transition: 0.1s all ease-in-out;
}

.sidebar-link__text {
  font-size: 10px;
  transition: 0.3s all ease-in-out;
  color: var(--text-color);
}

.round-animator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -10;
  border-radius: 20%;
  height: 50vh;
  width: 50vh;
  background-color: var(--text-color);
  transition: all 0.9s ease-in-out;
}

@keyframes scaler {
  0% {
    transform: translate(-50%, -50%) scale(0.1);
  }
  10% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(2);
  }
  50% {
    transform: translate(-50%, -50%) scale(3);
  }
  75% {
    transform: translate(-50%, -50%) scale(2);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

.navbar {
  height: 70px !important;
  box-shadow: inset 0 0 15px var(--bs-color);
  background-color: var(--bg-color) !important;
}

.brand {
  padding: 5px;
  color: var(--bg-color) !important;
}

.brand-logo {
}

.brand-text {
  font-size: 1.4rem;
  color: var(--text-color) !important;
  font-weight: 900;
}
.sb-brand-text {
  font-size: 1.4rem;
  color: var(--text-color) !important;
  font-weight: 900;
}
.nav-link {
  font-weight: 700 !important;
  color: var(--text-color) !important;
  border-radius: 12px;
  transition: all 0.3s ease-in-out !important;
}
.nav-link__icon {
}
.nav-link__text {
}

.nav-link.active {
  border: 1px solid var(--text-color);
  border-radius: 12px;
}
/* bottm navigation */

.bn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: var(--bg-color);
  padding: 5px;
  box-shadow: inset 0 0 15px var(--bs-color);
}

.bn-link {
  color: var(--text-color);
  cursor: pointer;
  border-radius: 50px;
  width: 70px;
  height: 40px;
  transition: all 0.3s ease-in-out;
}
.bn-link.active {
  background-color: var(--text-color);
  color: var(--bg-color);
}
.bn-link__ico,
.bn-link__text {
  user-select: none;
}
.bn-link__icon {
  font-size: 1rem;
}
.bn-link__text {
  font-weight: 600;
  font-size: 0.6rem;
}
.bn-link.active .bn-link__text {
  font-weight: 900;
}
.user-content {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid var(--text-color);
  /* border-radius: 10px; */
}

@media screen and (max-width: 659px) {
  .navbar {
    height: 50px !important;
  }
}

.ez-popup-menu::after {
  content: "";
  height: 20px;
  width: 20px;
  background: var(--bg-color);
  box-shadow: inset 0 0 15px 10px var(--bs-color);
  position: absolute;
  bottom: -5px;
  right: 25px;
  z-index: -999 !important;
  transform: rotate(45deg);
}

body.dark .nav-link:hover {
  box-shadow: 0 0 15px rgba(2 0 0 / 2000%);
}
.nav-link:hover {
  box-shadow: 0 0 5px rgba(0 0 0 / 60%);
}
