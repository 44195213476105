.codex-editor {
  /* border-radius: 10px;
  background: var(--bg-color);
  color: var(--text-color);
  margin: 10px auto;
  padding: 10px;
  min-width: none !important;
  box-shadow: 0 0 10px var(--bs-color); */
}
.ce-block__content {
  max-width: none !important;
}
/* .ce-inline-toolbar {
  color: var(--text-color);
  background: var(--bg-color);
} */

body.dark .ce-inline-toolbar,
body.dark .ce-inline-tool,
body.dark .ce-inline-toolbar__dropdown,
body.dark .ce-conversion-toolbar,
.ce-inline-tool-input {
  border-radius: 8px;
  color: var(--text-color);
  background: var(--bg-color);
}

body.dark .ce-conversion-tool__icon svg {
  color: var(--bg-color) !important;
}
body.dark .ce-conversion-tool:hover{
    background:var(--input-color) !important;
    color: var(--bg-color) !important;
}

body.dark .ce-toolbox__button,
body.dark .ce-toolbar__plus {
  color: var(--bg-color) !important;
}

.blog-editor .codex-editor {
  border-radius: 10px;
  background: var(--input-color);
  color: var(--text-color);
  margin: 0 0 10px 0;
  padding: 10px;
  /* min-width: none !important; */
  /* box-shadow: 0 0 10px var(--bs-color); */
}

.blog-renderer .codex-editor {
    /* background: var(--input-color); */
    color: var(--text-color);
  }